import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ButtonComponent } from '../../../../../../components/button/button.component';
import { IconComponent } from '../../../../../../components/icon/icon.component';
import { ModalLayoutComponent } from '../../../../../../components/modal-layout/modal-layout.component';
import { UserRole } from '../../../../../../interfaces/user.interface';
import { OrganisationActions } from '../../../../../../store/actions/organisation.actions';
import { selectOrganisation } from '../../../../../../store/selectors/organisation.selector';

@Component({
  selector: 'app-admin-access-modal',
  standalone: true,
  templateUrl: './admin-access.component.html',
  imports: [CommonModule, ButtonComponent, IconComponent, ModalLayoutComponent],
})
export class AdminAccessModalComponent implements OnDestroy {
  @Input()
  public show = false;

  @Input({ required: true })
  public userId = '';

  @Input({ required: true })
  public userName = '';

  @Input({ required: true })
  public userRole: UserRole = 'USER';

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  public error: unknown = undefined;
  public pending = false;
  public view: 'initial' | 'success' = 'initial';

  private readonly organisationSub = this.store
    .pipe(select(selectOrganisation))
    .subscribe(({ error, pending }) => {
      this.error = error;
      this.pending = pending;
    });

  public constructor(private readonly store: Store) {}

  public ngOnDestroy(): void {
    this.organisationSub?.unsubscribe();

    this.view = 'initial';
    this.pending = false;
    this.error = undefined;
  }

  public async updateUserRole(): Promise<void> {
    this.view = 'success';

    this.store.dispatch(
      OrganisationActions.updateUserRole({
        userId: encodeURIComponent(this.userId),
        role: this.userRole,
      }),
    );
  }

  public close(): void {
    this.store.dispatch(OrganisationActions.clearErrorState());
    this.closed.emit();
    this.view = 'initial';
    this.pending = false;
    this.error = undefined;
  }
}
